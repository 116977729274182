<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" title="Product Information Management"></navbar>

    <div class="container-fluid row">
      <div v-if="showNavbar" class="col-xl-12">
        <div class="row">
          <div class="col-xl-12 block br-t-l-0 mb-3">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="navbarTitel"
            ></head-menu>
            <div class="row mt-2 d-flex justify-content-between">
              <div class="ml-4 mr-2">
                <button
                  v-if="editable"
                  v-shortkey.once="['ctrl', 's']"
                  class="btn btn-success mr-2"
                  @click="speichern"
                  @shortkey="speichern"
                >
                  Speichern
                </button>
                <button class="btn btn-primary mr-2" @click="abbrechen">
                  <font-awesome-icon icon="fa-solid fa-list" class="mr-2" />
                  Zur Liste
                </button>
                <button
                  class="btn mr-2"
                  :class="istSpezialfall ? 'btn-warning' : 'btn-grey'"
                  @click="setSpezialfall(true)"
                >
                  Produktart: Spezial
                </button>
                <button
                  class="btn mr-2"
                  :class="!istSpezialfall ? 'btn-warning' : 'btn-grey'"
                  @click="setSpezialfall(false)"
                >
                  Produktart: Standard
                </button>
              </div>
              <!--<div class="mr-4">
                <button class="btn btn-danger" @click="oeffneLoeschenModal">
                  <font-awesome-icon class="mr-1" icon="fa-duotone fa-trash" />
                  <span>Löschen</span>
                </button>
              </div>-->
            </div>
          </div>
        </div>
      </div>

      <!-- PRODUKTART: SPEZIAL -->
      <!-- START Linke Seite -->
      <div
        class="col-xl-6 pr-4"
        :class="{ 'col-xl-12': !showNavbar }"
        v-if="istSpezialfall == true"
      >
        <div class="row">
          <div
            class="block block-kopf clickable"
            @click="setStammdatenSprache('DE')"
            :style="
              stammdatenSprache == 'DE' ? styleHeadlineSelected : styleHeadline
            "
          >
            <span class="col primary-headline-text p-0">Stammdaten - DE</span>
          </div>
          <div
            class="block block-kopf clickable"
            @click="setStammdatenSprache('EN')"
            :style="
              stammdatenSprache == 'EN' ? styleHeadlineSelected : styleHeadline
            "
          >
            <span class="col primary-headline-text p-0">EN</span>
          </div>
          <div
            class="block block-kopf clickable"
            @click="setStammdatenSprache('FR')"
            :style="
              stammdatenSprache == 'FR' ? styleHeadlineSelected : styleHeadline
            "
          >
            <span class="col primary-headline-text p-0">FR</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <input
                      v-if="stammdatenSprache == 'DE'"
                      v-model="produkt.bezeichnungde"
                      class="form-control"
                      placeholder="Bezeichnung DE"
                      :readonly="!editable"
                    />
                    <input
                      v-if="stammdatenSprache == 'EN'"
                      v-model="produkt.bezeichnungen"
                      class="form-control"
                      placeholder="Bezeichnung EN"
                      :readonly="!editable"
                    />
                    <input
                      v-if="stammdatenSprache == 'FR'"
                      v-model="produkt.bezeichnungfr"
                      class="form-control"
                      placeholder="Bezeichnung FR"
                      :readonly="!editable"
                    />
                    <label>Bezeichnung/Titel</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <input
                      v-if="stammdatenSprache == 'DE'"
                      v-model="produkt.kurzbezeichnungde"
                      class="form-control"
                      placeholder="Kurzezeichnung DE"
                      :readonly="!editable"
                    />
                    <input
                      v-if="stammdatenSprache == 'EN'"
                      v-model="produkt.kurzbezeichnungen"
                      class="form-control"
                      placeholder="Kurzezeichnung EN"
                      :readonly="!editable"
                    />
                    <input
                      v-if="stammdatenSprache == 'FR'"
                      v-model="produkt.kurzbezeichnungfr"
                      class="form-control"
                      placeholder="Kurzezeichnung FR"
                      :readonly="!editable"
                    />
                    <label>Kurzbezeichnung</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <textarea
                      v-if="stammdatenSprache == 'DE'"
                      v-model="produkt.beschreibung_de"
                      class="form-control"
                      placeholder="Beschreibung DE"
                      :readonly="!editable"
                    />
                    <textarea
                      v-if="stammdatenSprache == 'EN'"
                      v-model="produkt.beschreibung_en"
                      class="form-control"
                      placeholder="Beschreibung EN"
                      :readonly="!editable"
                    />
                    <textarea
                      v-if="stammdatenSprache == 'FR'"
                      v-model="produkt.beschreibung_fr"
                      class="form-control"
                      placeholder="Beschreibung FR"
                      :readonly="!editable"
                    />
                    <label>Beschreibung</label>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <ckeditor
                      v-if="stammdatenSprache == 'DE'"
                      :editor="editor"
                      v-model="produkt.zusatzinformationen_de"
                      :disabled="!editable"
                      :config="editorConfig"
                      :key="'DE' + stammdatenSprache"
                    ></ckeditor>
                    <ckeditor
                      v-if="stammdatenSprache == 'EN'"
                      :editor="editor"
                      v-model="produkt.zusatzinformationen_en"
                      :disabled="!editable"
                      :config="editorConfig"
                      :key="'EN' + stammdatenSprache"
                    ></ckeditor>
                    <ckeditor
                      v-if="stammdatenSprache == 'FR'"
                      :editor="editor"
                      v-model="produkt.zusatzinformationen_fr"
                      :disabled="!editable"
                      :config="editorConfig"
                      :key="'FR' + stammdatenSprache"
                    ></ckeditor>
                    <label>Zusatzinformationen</label>
                  </div>
                </div>
              </div>

              <!-- Funktion Mehrere Bilder pro Produkt
              <div class="row mb-3 mt-5">
                <div class="col-xl-6">
                  <div class="btn btn-primary" @click="bildObjektHinzufügen()">
                    <font-awesome-icon
                      class="px-2 mr-1"
                      icon="fa-regular fa-plus"
                    /><span>Bild hinzufügen</span>
                  </div>
                </div>
              </div>
			  
			  
              <div
                class="row py-2 border-bottom"
                v-for="(bild, index) in produkt.bilder"
                :key="'bild-' + index"
              >
                <div class="col-4 position-relative">
                  <img
                    v-if="bild && bild.bildURL && Object.keys(bild).length != 0"
                    class="w-100 h-100"
                    :src="bild.bildURL"
                    style="
                      object-fit: contain;
                      object-position: center center;
                      max-height: 200px;
                    "
                  />
                  <div
                    class="w-100 h-100 bg-medium-blue"
                    v-if="
                      !bild || !bild.bildURL || Object.keys(bild).length === 0
                    "
                  ></div>
                  <div class="position-absolute top-0 right-53">
                    <div class="form-group d-flex">
                      <div
                        class="btn btn-sm btn-success ml-1 p-0"
                        @click="oeffneBildSucheModal(index)"
                      >
                        <font-awesome-icon
                          class="px-2"
                          icon="fa-regular fa-plus"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="position-absolute top-0 right-20">
                    <div class="form-group d-flex">
                      <div
                        class="btn btn-sm btn-danger ml-1 p-0"
                        @click="loescheBild(index)"
                      >
                        <font-awesome-icon
                          class="px-2"
                          icon="fa-duotone fa-trash"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-7">
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="bild.dateinamede"
                        class="form-control"
                        placeholder="Dateiname DE"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="bild.dateinameen"
                        class="form-control"
                        placeholder="Dateiname DE"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="bild.dateinamefr"
                        class="form-control"
                        placeholder="Dateiname DE"
                        :readonly="!editable"
                      />
                      <label>Bezeichnung</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="bild.titletagde"
                        class="form-control"
                        placeholder="Titel Tag DE"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="bild.titletagen"
                        class="form-control"
                        placeholder="Titel Tag DE"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="bild.titletagfr"
                        class="form-control"
                        placeholder="Titel Tag DE"
                        :readonly="!editable"
                      />
                      <label>Titel Tag</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="bild.alttagde"
                        class="form-control"
                        placeholder="Alt Tag DE"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="bild.alttagen"
                        class="form-control"
                        placeholder="Alt Tag DE"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="bild.alttagfr"
                        class="form-control"
                        placeholder="Alt Tag DE"
                        :readonly="!editable"
                      />
                      <label>Alt Tag</label>
                    </div>
                  </div>
                </div>
                <div class="col-1">
                  <div class="clickable" @click="bildObjektEntfernen(index)">
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-regular fa-xmark"
                    />
                  </div>
                </div>
              </div>
			-->

              <div class="row mt-2">
                <div class="col-4 position-relative">
                  <img
                    v-if="
                      produkt.bild &&
                      Object.keys(produkt.bild).length != 0 &&
                      produkt.bild.bildURL
                    "
                    class="w-100 h-100"
                    :src="produkt.bild.bildURL"
                    style="
                      object-fit: contain;
                      object-position: center center;
                      max-height: 200px;
                    "
                  />
                  <div
                    class="w-100 h-100 bg-medium-blue"
                    v-if="
                      !produkt.bild ||
                      Object.keys(produkt.bild).length === 0 ||
                      !produkt.bild.bildURL
                    "
                  ></div>
                  <div class="position-absolute top-0 right-53">
                    <div class="form-group d-flex">
                      <div
                        class="btn btn-sm btn-success ml-1 p-0"
                        @click="oeffneBildSucheModal()"
                      >
                        <font-awesome-icon
                          class="px-2"
                          icon="fa-regular fa-plus"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="position-absolute top-0 right-20">
                    <div class="form-group d-flex">
                      <div
                        class="btn btn-sm btn-danger ml-1 p-0"
                        @click="loescheBild()"
                      >
                        <font-awesome-icon
                          class="px-2"
                          icon="fa-duotone fa-trash"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-8">
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produkt.bild.dateinamede"
                        class="form-control"
                        placeholder="Dateiname DE"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produkt.bild.dateinameen"
                        class="form-control"
                        placeholder="Dateiname EN"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produkt.bild.dateinamefr"
                        class="form-control"
                        placeholder="Dateiname FR"
                        :readonly="!editable"
                      />
                      <label>Bezeichnung</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produkt.bild.titletagde"
                        placeholder="Titel Tag DE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produkt.bild.titletagen"
                        placeholder="Titel Tag EN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produkt.bild.titletagfr"
                        placeholder="Titel Tag FR"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Titel Tag</label>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produkt.bild.alttagde"
                        placeholder="Alt Tag DE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produkt.bild.alttagen"
                        placeholder="Alt Tag EN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produkt.bild.alttagfr"
                        placeholder="Alt Tag FR"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Alt Tag</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-2 mt-5">
                <div class="col-xl-6">
                  <div class="btn btn-primary" @click="addDokument()">
                    <font-awesome-icon
                      class="px-2 mr-1"
                      icon="fa-regular fa-plus"
                    /><span>Dokument hinzufügen</span>
                  </div>
                </div>
              </div>
              <div
                class="row py-2 border-bottom"
                v-for="(dokument, index) in produkt.dokumente"
                :key="'dokument-' + index"
              >
                <div class="col-12 d-flex">
                  <div class="col-5 p-0">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="dokument.anzeige_de"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="dokument.anzeige_en"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="dokument.anzeige_fr"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Anzeige</label>
                    </div>
                  </div>
                  <div class="col-5">
                    <div class="form-group">
                      <input
                        v-model="dokument.bezeichnung"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Bezeichnung</label>
                    </div>
                  </div>
                  <div class="col-1">
                    <div class="form-group d-flex">
                      <div
                        class="btn btn-sm btn-success ml-1"
                        @click="oeffneDokumentSucheModal(index)"
                      >
                        <font-awesome-icon
                          class="px-2"
                          icon="fa-regular fa-plus"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="col-1">
                    <div class="form-group d-flex">
                      <div
                        class="btn btn-sm btn-danger ml-1"
                        @click="loescheDokument(index)"
                      >
                        <font-awesome-icon
                          class="px-2"
                          icon="fa-duotone fa-trash"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ENDE STAMMDATEN -->
      <!-- ENDE LINKE SEITE -->

      <!-- START RECHTE SEITE -->
      <!-- START Technische Daten -->
      <div
        class="col-xl-6 pl-4"
        :class="{ 'col-xl-12': !showNavbar }"
        v-if="istSpezialfall == true"
      >
        <div class="row">
          <div
            class="block block-kopf clickable"
            @click="setTechnischeDatenSprache('DE')"
            :style="
              technischeDatenSprache == 'DE'
                ? { filter: 'none' }
                : { filter: 'brightness(0.9)' }
            "
          >
            <span class="col primary-headline-text p-0"
              >Technische Daten - DE</span
            >
          </div>
          <div
            class="block block-kopf clickable"
            @click="setTechnischeDatenSprache('EN')"
            :style="
              technischeDatenSprache == 'EN'
                ? { filter: 'none' }
                : { filter: 'brightness(0.9)' }
            "
          >
            <span class="col primary-headline-text p-0">EN</span>
          </div>
          <div
            class="block block-kopf clickable"
            @click="setTechnischeDatenSprache('FR')"
            :style="
              technischeDatenSprache == 'FR'
                ? { filter: 'none' }
                : { filter: 'brightness(0.9)' }
            "
          >
            <span class="col primary-headline-text p-0">FR</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div
              class="eingabe-panel-kopf p-3 technischeDatenPanel"
              ref="technischeDatenPanel"
              :style="
                produkt.wertliste.length > 4 ? panelStyle : 'height: 420px;'
              "
            >
              <!-- TODO: Technische Daten hinzufügen
              <div class="row mb-2">
                <div class="col-xl-6">
                  <div class="btn btn-primary" @click="addTechnischeDaten()">
                    <font-awesome-icon
                      class="px-2 mr-1"
                      icon="fa-regular fa-plus"
                    /><span>Technische Daten hinzufügen</span>
                  </div>
                </div>
              </div>
			  -->
              <div
                class="py-2 border-bottom"
                v-for="(tDaten, index) in produkt.wertliste"
                :key="'tDaten' + index"
              >
                <div class="row">
                  <div class="col-xl-4">
                    <div class="form-group d-flex" v-if="tDaten.produktfeld">
                      <input
                        v-if="technischeDatenSprache == 'DE'"
                        v-model="tDaten.produktfeld.bezeichnung_de"
                        placeholder="Bezeichnung DE"
                        class="form-control"
                        :readonly="true"
                      />
                      <input
                        v-if="technischeDatenSprache == 'EN'"
                        v-model="tDaten.produktfeld.bezeichnung_en"
                        placeholder="Bezeichnung EN"
                        class="form-control"
                        :readonly="true"
                      />
                      <input
                        v-if="technischeDatenSprache == 'FR'"
                        v-model="tDaten.produktfeld.bezeichnung_fr"
                        placeholder="Bezeichnung FR"
                        class="form-control"
                        :readonly="true"
                      />
                      <label>Bezeichnung</label>
                    </div>
                  </div>
                  <div class="col-xl-3">
                    <div class="form-group d-flex" v-if="tDaten">
                      <input
                        v-if="technischeDatenSprache == 'DE'"
                        v-model="tDaten.wert_de"
                        placeholder="Wert DE"
                        class="form-control"
                      />
                      <input
                        v-if="technischeDatenSprache == 'EN'"
                        v-model="tDaten.wert_en"
                        placeholder="Wert EN"
                        class="form-control"
                      />
                      <input
                        v-if="technischeDatenSprache == 'FR'"
                        v-model="tDaten.wert_fr"
                        placeholder="Wert FR"
                        class="form-control"
                      />
                      <label>Wert</label>
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group d-flex" v-if="tDaten.einheit">
                      <input
                        v-if="technischeDatenSprache == 'DE'"
                        v-model="tDaten.einheit.bezeichnung_de"
                        placeholder="Wert DE"
                        class="form-control"
                        :readonly="true"
                      />
                      <input
                        v-if="technischeDatenSprache == 'EN'"
                        v-model="tDaten.einheit.bezeichnung_en"
                        placeholder="Wert EN"
                        class="form-control"
                        :readonly="true"
                      />
                      <input
                        v-if="technischeDatenSprache == 'FR'"
                        v-model="tDaten.einheit.bezeichnung_fr"
                        placeholder="Wert FR"
                        class="form-control"
                        :readonly="true"
                      />
                      <label>Einheit</label>
                    </div>
                  </div>
                  <div class="col-xl-2">
                    <div class="form-group d-flex" v-if="tDaten.produktfeld">
                      <input
                        v-model.number="tDaten.produktfeld.sortierung"
                        placeholder="Sortierung"
                        class="form-control"
                        :readonly="true"
                      />
                      <label>Sortierung</label>
                    </div>
                  </div>
                  <!--<div class="col-xl-1">
                    <div class="form-group d-flex">
                      <div
                        class="btn btn-sm btn-danger ml-1"
                        @click="loescheTechnischeDaten(index)"
                      >
                        <font-awesome-icon
                          class="px-2"
                          icon="fa-duotone fa-trash"
                        />
                      </div>
                    </div>
                  </div>
				  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--ENDE Technische Daten -->
      <!-- ENDE RECHTE SEITE -->
      <!-- ENDE PRODUKTART SPEZIAL -->

      <!-- START PRODUKTART STANDARD -->
      <!-- START LINKE SEITE -->

      <div
        class="col-xl-6 pr-4"
        :class="{ 'col-xl-12': !showNavbar }"
        v-if="istSpezialfall == false"
      >
        <div class="row">
          <div
            class="block block-kopf clickable"
            @click="setStammdatenSprache('DE')"
            :style="
              stammdatenSprache == 'DE' ? styleHeadlineSelected : styleHeadline
            "
          >
            <span class="col primary-headline-text p-0">Stammdaten - DE</span>
          </div>
          <div
            class="block block-kopf clickable"
            @click="setStammdatenSprache('EN')"
            :style="
              stammdatenSprache == 'EN' ? styleHeadlineSelected : styleHeadline
            "
          >
            <span class="col primary-headline-text p-0">EN</span>
          </div>
          <div
            class="block block-kopf clickable"
            @click="setStammdatenSprache('FR')"
            :style="
              stammdatenSprache == 'FR' ? styleHeadlineSelected : styleHeadline
            "
          >
            <span class="col primary-headline-text p-0">FR</span>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-xl-12 block br-t-l-0">
            <div class="eingabe-panel-kopf p-3">
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <input
                      v-if="stammdatenSprache == 'DE'"
                      v-model="produkt.bezeichnungde"
                      class="form-control"
                      placeholder="Bezeichnung DE"
                      :readonly="!editable"
                    />
                    <input
                      v-if="stammdatenSprache == 'EN'"
                      v-model="produkt.bezeichnungen"
                      class="form-control"
                      placeholder="Bezeichnung EN"
                      :readonly="!editable"
                    />
                    <input
                      v-if="stammdatenSprache == 'FR'"
                      v-model="produkt.bezeichnungfr"
                      class="form-control"
                      placeholder="Bezeichnung FR"
                      :readonly="!editable"
                    />
                    <label>Bezeichnung</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-12">
                  <div class="form-group">
                    <input
                      v-if="stammdatenSprache == 'DE'"
                      v-model="produkt.kurzbezeichnungde"
                      class="form-control"
                      placeholder="Kurzezeichnung DE"
                      :readonly="!editable"
                    />
                    <input
                      v-if="stammdatenSprache == 'EN'"
                      v-model="produkt.kurzbezeichnungen"
                      class="form-control"
                      placeholder="Kurzezeichnung EN"
                      :readonly="!editable"
                    />
                    <input
                      v-if="stammdatenSprache == 'FR'"
                      v-model="produkt.kurzbezeichnungfr"
                      class="form-control"
                      placeholder="Kurzezeichnung FR"
                      :readonly="!editable"
                    />
                    <label>Kurzbezeichnung</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="produkt.lieferant_pid"
                      class="form-control"
                      placeholder="Lieferant PID"
                      :readonly="!editable"
                    />
                    <label>Lieferant PID</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="produkt.lieferant_pid_bopla"
                      class="form-control"
                      placeholder="Lieferant PID Bopla"
                      :readonly="!editable"
                    />
                    <label>Lieferant PID Bopla</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="produkt.lieferant_pid_rose"
                      class="form-control"
                      placeholder="Lieferant PID Rose"
                      :readonly="!editable"
                    />
                    <label>Lieferant PID Rose</label>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="produkt.hersteller_pid"
                      class="form-control"
                      placeholder="Hersteller PID"
                      :readonly="!editable"
                    />
                    <label>Hersteller PID</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="produkt.hersteller_typ"
                      class="form-control"
                      placeholder="Hersteller Typ"
                      :readonly="!editable"
                    />
                    <label>Hersteller Typ</label>
                  </div>
                </div>
                <div class="col-xl-4">
                  <div class="form-group">
                    <input
                      v-model="produkt.modelnummer"
                      class="form-control"
                      placeholder="Modelnummer"
                      :readonly="!editable"
                    />
                    <label>Modelnummer</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ENDE LINKE SEITE -->
      <!-- START RECHTE SEITE -->
      <!-- ENDE RECHTE SEITE -->

      <!-- REITER START -->

      <!-- HEADER REITER -->

      <div class="col-12" v-if="istSpezialfall == false">
        <div class="row">
          <div class="col-12 p-0">
            <ul class="nav nav-tabs mt-3 fs-28">
              <li class="nav item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige == 0 }"
                  :to="{
                    name: 'produkt',
                    params: { produktid: produktid, anzeige: 0 },
                  }"
                  >Features</router-link
                >
              </li>
              <li class="nav item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige == 1 }"
                  :to="{
                    name: 'produkt',
                    params: { produktid: produktid, anzeige: 1 },
                  }"
                  >Dateien</router-link
                >
              </li>
              <!-- 
			  <li class="nav item">
                <router-link
                  class="primary-headline-text nav-link"
                  :class="{ active: anzeige == 2 }"
                  :to="{
                    name: 'produkt',
                    params: { produktid: produktid, anzeige: 2 },
                  }"
                  >Zubehör</router-link
                >
              </li>
			  -->
            </ul>
          </div>

          <!-- ENDE HEADER REITER -->

          <!-- REITER CONTENT -->
          <div class="col-12 p-0">
            <div class="tab-container p-3">
              <div v-show="anzeige === 0">
                <feature-reiter
                  :features="
                    produkt.feature_gruppe
                      ? produkt.feature_gruppe.features
                      : null
                  "
                  :produktid="produktid"
                />
              </div>
              <div v-show="anzeige === 1">
                <dateien-reiter :produktid="produktid" :mimes="produkt.mimes" />
              </div>
              <!--
			  <div v-show="anzeige === 2">
                <zubehoer-reiter
                  :produktid="produktid"
                  :zubehoer="produkt.zubehoer"
                />
              </div>
			  -->
            </div>
          </div>
          <!-- REITER CONTENT ENDE -->
        </div>
      </div>
      <!-- REITER ENDE -->

      <!-- ENDE PRODUKTART STANDARD -->
    </div>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal id="produkt-loeschen-modal" @confirmed="produktLoeschen" />

    <change-watcher-alert />

    <bild-suche-modal id="bild-suche-modal" @confirmed="setzeBild" />

    <dokument-suche-modal
      id="dokument-suche-modal"
      :index="dokumentIndex"
      @confirmed="setzeDokument"
    />
  </div>
</template>
  
  <script>
import Api from "@/Api";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import LoeschenModal from "@/components/Modals/LoeschenModal.vue";
import BildSucheModal from "@/components/Modals/BildSucheModal.vue";
import DokumentSucheModal from "@/components/Modals/DokumentSucheModal.vue";

import store from "@/store";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import FeatureReiter from "@/components/Reiter/Featurereiter.vue";
//import ZubehoerReiter from "@/components/Reiter/Zubehoerreiter.vue";
import DateienReiter from "@/components/Reiter/Dateienreiter.vue";

export default {
  name: "Produkt",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    BildSucheModal,
    DokumentSucheModal,
    FeatureReiter,
    //ZubehoerReiter,
    DateienReiter,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    produktid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      editable: true,
      istSpezialfall: false,
      stammdatenSprache: "DE",
      technischeDatenSprache: "DE",
      styleHeadlineSelected: { filter: "none" },
      styleHeadline: { filter: "brightness(0.9)" },
      dokumentIndex: 0,
      bildIndex: 0,
      produkt: {
        id: "",
        dokumente: [
          {
            id: "",
            anzeige_de: "",
            anzeige_en: "",
            anzeige_fr: "",
            bezeichnung: "",
          },
        ], //Spezial
        bild: {
          bildURL: null,
          dateinamede: null,
          titletagde: null,
          alttagde: null,
          dateinameen: null,
          titletagen: null,
          alttagen: null,
          dateinamefr: null,
          titletagfr: null,
          alttagfr: null,
        }, //Spezial
        bezeichnungde: "",
        bezeichnungen: "",
        bezeichnungfr: "",
        kurzbezeichnungde: "",
        kurzbezeichnungen: "",
        kurzbezeichnungfr: "",
        beschreibung_de: "", //Spezial
        beschreibung_en: "", //Spezial
        beschreibung_fr: "", //Spezial
        zusatzinformationen_de: "", //Spezial
        zusatzinformationen_en: "", //Spezial
        zusatzinformationen_fr: "", //Spezial
        //produktfelder: {}, //Spezial, auf wertliste verknüpft
        wertliste: [
          {
            wert_de: "",
            wert_en: "",
            wert_fr: "",
            produktfeld: {
              sortierung: 1,
              bezeichnung_de: "",
              bezeichnung_en: "",
              bezeichnung_fr: "",
            },
            einheit: {
              bezeichnung_de: "",
              bezeichnung_en: "",
              bezeichnung_fr: "",
            },
          },
        ], //Spezial
        lieferant_pid: "" /* Standard */,
        lieferant_pid_bopla: "" /* Standard */,
        lieferant_pid_rose: "" /* Standard */,
        internationale_pid: "" /* Standard */,
        hersteller_pid: "" /* Standard */,
        feature_gruppe: {
          features: [
            {
              wertbezeichnungde: "",
              wertbezeichnungen: "",
              wertbezeichnungfr: "",
              secondarywert: "",
              wert: "",
              feature_name: {
                //allgemeiner Name zb Masse
                anzeige: null,
                bezeichnung: "",
                beschreibungde: "",
                beschreibungen: "",
                beschreibungfr: "",
              },
            },
          ],
        } /* Standard */,
        zubehoer: [
          { referenz_id: "" },
          { referenz_id: "" },
          { referenz_id: "" },
        ] /* Standard */,
        mimes: [
          {
            mime_type: "",
            mime_source: "",
            mime_desc: "",
            mime_alt: "",
            mime_purpose: "",
          },
        ] /* Standard */,
        modelnummer: "" /* Standard */,
        //produkt_logistik: {},
        //hersteller
      },
      panelStyle: {
        height: "420px",
        "overflow-y": "scroll",
      },
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "insertTable",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id || this.produktid) {
        return "Produkt";
      } else {
        return "Neues Produkt";
      }
    },
  },
  watch: {
    produktid: {
      handler: function (val) {
        if (val) {
          this.ladeProdukt(val);
        }
      },
      immediate: true,
    },
  },

  created() {},

  mounted() {},

  methods: {
    ladeProdukt(id) {
      this.loading = true;

      Api.get("produkt/", {
        params: { id: id },
      })
        .then((response) => {
          this.initializeProdukt(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initializeProdukt(data) {
      this.produkt = data;

      const PRODUDUKTART_SPEZIAL = "18170EEF47A";

      if (this.produkt.produktart?.id === PRODUDUKTART_SPEZIAL) {
        this.setSpezialfall(true);
      } else {
        this.setSpezialfall(false);
      }

      if (!this.produkt.bild) {
        this.produkt.bild = {
          bildURL: null,
          dateinamede: null,
          titletagde: null,
          alttagde: null,
          dateinameen: null,
          titletagen: null,
          alttagen: null,
          dateinamefr: null,
          titletagfr: null,
          alttagfr: null,
        };
      }

      if (!this.produkt.wertliste) {
        this.produkt.wertliste = {
          wert_de: null,
          wert_en: null,
          wert_fr: null,
          produktfeld: {
            sortierung: 0,
            bezeichnung_de: null,
            bezeichnung_en: null,
            bezeichnung_fr: null,
          },
          einheit: {
            bezeichnung_de: null,
            bezeichnung_en: null,
            bezeichnung_fr: null,
          },
        };
      }

      if (!this.produkt.zusatzinformationen_de)
        this.produkt.zusatzinformationen_de = "";
      if (!this.produkt.zusatzinformationen_en)
        this.produkt.zusatzinformationen_en = "";
      if (!this.produkt.zusatzinformationen_fr)
        this.produkt.zusatzinformationen_fr = "";
    },
    setSpezialfall(spezialfall) {
      if (spezialfall) {
        this.istSpezialfall = true;
        this.produkt.produktart = { id: "18170EEF47A", bezeichnung: "Spezial" };
      } else {
        this.istSpezialfall = false;
        this.produkt.produktart = {
          id: "18170EEE277",
          bezeichnung: "Standard",
        };
      }
    },
    setStammdatenSprache(sprache) {
      this.stammdatenSprache = sprache;
    },
    setTechnischeDatenSprache(sprache) {
      this.technischeDatenSprache = sprache;
    },
    abbrechen() {
      this.$router.push({ name: "produkt-liste" });
    },

    speichern() {
      var json = Object.assign({}, this.produkt);

      this.loading = true;

      Api.put("produkt/", json, {
        params: { id: this.produkt.id },
      })
        .then((response) => {
          this.initializeProdukt(response.data);
          this.$notify({
            type: "success",
            title: "Aktion erfolgreich!",
            text: "Produkt gespeichert.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "produkt-loeschen-modal");
    },

    produktLoeschen() {},
    /*
    bildObjektHinzufügen() {
      this.produkt.bilder.push({
        bildURL: "",
        dateinamede: "",
        titletagde: "",
        alttagde: "",
        dateinameen: "",
        titletagen: "",
        alttagen: "",
        dateinamefr: "",
        titletagfr: "",
        alttagfr: "",
      });
    },
    bildObjektEntfernen(index) {
      this.produkt.bilder.splice(index, 1);
    },*/
    oeffneBildSucheModal() {
      this.$bus.$emit("oeffneBildSucheModal", "bild-suche-modal");
    },
    setzeBild(bild) {
      this.$set(this.produkt, "bild", bild);
    },
    loescheBild() {
      this.$set(this.produkt, "bild", {});
    },
    addDokument() {
      this.produkt.dokumente.push({
        id: "ID",
        anzeige_de: "",
        anzeige_en: "",
        anzeige_fr: "",
        bezeichnung: "",
      });
    },
    oeffneDokumentSucheModal(index) {
      this.dokumentIndex = index;
      this.$bus.$emit("oeffneDokumentSucheModal", "dokument-suche-modal");
    },
    loescheDokument(index) {
      this.produkt.dokumente.splice(index, 1);
    },
    setzeDokument(dokument) {
      this.$set(this.produkt.dokumente, this.dokumentIndex, dokument);
    },
    addTechnischeDaten() {
      this.produkt.wertliste.push({
        wert: "",
        produktfeld: {
          sortierung: 0,
          bezeichnung_de: "",
          bezeichnung_en: "",
          bezeichnung_fr: "",
        },
        einheit: {
          bezeichnung: "",
        },
      });
    },
    loescheTechnischeDaten(index) {
      this.produkt.wertliste.splice(index, 1);
    },
  },
};
</script>
  
  <style>
</style>
  