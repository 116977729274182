<template>
  <div class="list">
    <notifications></notifications>
    <navbar title="Product Information Management"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              headline="Filter"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.bezeichnung_de"
                        class="form-control input"
                        type="text"
                        placeholder="Bezeichnung DE"
                      />
                      <label>Bezeichnung DE</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.pfad_de"
                        class="form-control input"
                        type="text"
                        placeholder="Pfad DE"
                      />
                      <label>Pfad DE</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.pfad_en"
                        class="form-control input"
                        type="text"
                        placeholder="Pfad EN"
                      />
                      <label>Pfad EN</label>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model.trim="filters.pfad_fr"
                        class="form-control input"
                        type="text"
                        placeholder="Pfad FR"
                      />
                      <label>Pfad FR</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              headline="Produktgruppen"
              :anzahl="anzahlDaten"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>
            <div class="row mb-2">
              <div class="block br-t-l-0 w-100-p br-t-l-xl-0">
                <div class="row mt-2 d-flex justify-content-between">
                  <div class="ml-4"></div>
                  <div class="mr-4">
                    <button class="btn btn-danger" @click="oeffneLoeschenModal">
                      <font-awesome-icon
                        class="mr-1"
                        icon="fa-duotone fa-trash"
                      />
                      <span>Löschen</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row block belegliste belegliste-small">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-compare="sortDates"
                    sort-icon-left
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">Lade weitere Daten...</div>
                    <div slot="no-more">Keine weiteren Daten</div>
                    <div slot="no-results">Keine weiteren Daten gefunden</div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <loeschen-modal
      id="produktgruppe-loeschen-modal"
      @confirmed="produktgruppenLoeschen"
      :selectedIds="selectedIds"
    />

    <loading-overlay v-show="loading"></loading-overlay>
  </div>
</template>
    
    <script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import store from "@/store";
import page from "@/mixins/Page";
import dashboard from "@/mixins/Dashboard";

import LoeschenModal from "@/components/Modals/LoeschenModal";

export default {
  name: "Produktgruppenliste",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    LoeschenModal,
  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + "Produkgruppen";
      },
    };
  },
  mixins: [page, dashboard],
  props: {},
  store,
  data() {
    return {
      sortBy: "",
      sortDesc: false,
      fields: [
        { key: "selected", label: "" },
        {
          key: "bezeichnung_de",
          label: "Bezeichnung DE",
          sortable: true,
          searchable: true,
        },
        {
          key: "pfad_de",
          label: "Pfad DE",
          sortable: true,
          searchable: true,
        },
        {
          key: "pfad_en",
          label: "Pfad EN",
          sortable: true,
          searchable: true,
        },
        {
          key: "pfad_fr",
          label: "Pfad FR",
          sortable: true,
          searchable: true,
        },
      ],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.bezeichnung_de = this.setQueryParam(this.filters.bezeichnung_de);
      params.pfad_de = this.setQueryParam(this.filters.pfad_de);
      params.pfad_en = this.setQueryParam(this.filters.pfad_en);
      params.pfad_fr = this.setQueryParam(this.filters.pfad_fr);

      return params;
    },
  },
  created() {
    this.initFilter("produktgruppen-liste", "produktgruppe/", true);
  },
  mounted() {},
  methods: {
    details(produktgruppe) {
      if (produktgruppe.id) {
        this.$router.push({
          name: "produktgruppe",
          params: { produktgruppeid: produktgruppe.id, anzeige: 0 },
        });
      }
    },

    oeffneLoeschenModal() {
      if (this.selectedIds.length > 0)
        this.$bus.$emit("openLoeschenModal", "produktgruppe-loeschen-modal");
      else {
        this.$notify({
          type: "warn",
          title: "Aktion fehlgeschlagen",
          text: "Keine Produktgruppen ausgewählt",
        });
      }
    },

    produktgruppenLoeschen() {
      this.delete("produktgruppe/", this.selectedIds);
    },
  },
};
</script>
    
    <style>
</style>
    