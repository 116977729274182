<template>
  <div class="row">
    <div
      :class="[colClass]"
      class="block-dark block-kopf-dark"
      :style="[
        isABBTS_Test()
          ? { 'background-color': '#248c8c' } // ABBTS-Testsystem Farbpalette
          : { 'background-color': '#124862' }, // reguläre Farbpalette
      ]"
    >
      <div class="row">
        <div class="col-xl-4">
          <span class="primary-headline-text text-white">
            <font-awesome-icon icon="fa-regular fa-calendar-alt" class="mr-2" />
            {{ timestamp }}
          </span>
        </div>
        <div class="col-xl-4 text-center">
          <span class="primary-headline-text text-white">{{ headline }}</span>
        </div>
        <div class="col-xl-4 text-right" v-if="anzahl">
          <span class="primary-headline-text text-white"
            >Anzahl gewählte Einträge: {{ anzahl }}
          </span>
        </div>
        <div class="col-xl-4 text-right" v-if="showTime">
          <span class="primary-headline-text text-white">{{ clockstamp }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";

import { faCalendarAlt } from "@fortawesome/pro-regular-svg-icons";

library.add(faCalendarAlt);

export default {
  name: "HeadMenu",
  props: {
    headline: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      required: false,
      default: 2,
    },
    //anzeige Anzahl Datensätze im Headmenu
    anzahl: {
      type: Number,
      required: false,
      default: null,
    },
    showTime: {
      type: Boolean,
      default: false,
    },
    updateClock: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      timestamp: "",
      clockstamp: "",
    };
  },
  computed: {
    colClass: function () {
      if (this.col) {
        return "col-xl-" + this.col;
      } else {
        return "col-xl-2";
      }
    },
  },
  watch: {},
  mounted() {
    this.getNow();
    this.getTime();

    if (this.updateClock) {
      let that = this;
      setInterval(function () {
        that.getNow();
        that.getTime();
      }, 15 * 1000); // 60 * 1000 milsec
    }
  },
  methods: {
    getNow: function () {
      const today = new Date();
      const date =
        new Date().toLocaleString("de", { weekday: "long" }) +
        " " +
        today.getDate() +
        ". " +
        new Date().toLocaleString("de", { month: "long" }) +
        " " +
        today.getFullYear();

      this.timestamp = date;
    },

    getTime: function () {
      //const today = new Date();
      const time = new Date().toLocaleTimeString("de-CH", {
        hour: "2-digit",
        minute: "2-digit",
      });

      this.clockstamp = time;
    },
    isABBTS_Test() {
      if (
        process.env.VUE_APP_SERVER_URL === "https://abbts-test.bx-education.ch/"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
</style>
