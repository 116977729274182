<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline class="col-xl-12" headline="Features" :col="3">
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4"></div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="features"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              show-empty
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>Fehler beim Laden</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>Keine Daten vorhanden.</strong>
                </div>
              </template>

              <template v-slot:cell(anzeige)="row">
                <div class="text-center">
                  <font-awesome-icon
                    v-if="row.item.featurename"
                    :icon="
                      row.item.featurename.anzeige
                        ? 'fa-solid fa-check'
                        : 'fa-light fa-times'
                    "
                  />
                </div>
              </template>

              <template v-slot:cell(edit)="row">
                <div
                  class="clickable text-center"
                  @click="oeffneFeatureBearbeitenModal(row.item, row.index)"
                >
                  <font-awesome-icon icon="fa-regular fa-pencil" />
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <feature-bearbeiten-modal
      @confirmed="featureUebernehmen"
      id="feature-bearbeiten-modal"
    />
  </div>
</template>
  
  <script>
import { BSpinner } from "bootstrap-vue";
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";
import FeatureBearbeitenModal from "@/components/Modals/FeatureBearbeitenModal.vue";

export default {
  name: "Featurereiter",
  components: {
    ContainerHeadline,
    FeatureBearbeitenModal,
    BSpinner,
  },
  mixins: [reiter],
  props: {
    shown: {
      type: Boolean,
      required: false,
    },
    produktid: {
      type: String,
      required: false,
    },
    features: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 50,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      featureIndex: 0,
      fields: [
        {
          key: "featurename.bezeichnung",
          sortable: true,
          label: "Feature Bezeichnung",
        },
        {
          key: "featurename.beschreibungde",
          sortable: true,
          label: "Feature Beschreibung DE",
        },
        {
          key: "featurename.beschreibungen",
          sortable: true,
          label: "Feature Beschreibung EN",
        },
        {
          key: "featurename.beschreibungfr",
          sortable: true,
          label: "Feature Beschreibung FR",
        },
        {
          key: "wert",
          sortable: true,
          label: "Wert",
        },
        {
          key: "secondarywert",
          sortable: true,
          label: "Sekundär-Wert",
        },
        {
          key: "wertbezeichnungde",
          sortable: true,
          label: "Wert-Bezeichnung DE",
        },
        {
          key: "wertbezeichnungen",
          sortable: true,
          label: "Wert-Bezeichnung EN",
        },
        {
          key: "wertbezeichnungfr",
          sortable: true,
          label: "Wert-Bezeichnung FR",
        },
        {
          key: "anzeige",
          sortable: true,
          label: "Anzeige",
        },
        {
          key: "edit",
          sortable: false,
          label: "Bearbeiten",
        },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  created() {},
  methods: {
    oeffneFeatureBearbeitenModal(feature, index) {
      this.featureIndex = index;
      this.$bus.$emit("oeffneFeatureBearbeitenModal", feature);
    },
    oeffneProduktSucheModal() {
      this.$bus.$emit("oeffneProduktSucheModal", "produkt-suche-modal");
    },
    featureUebernehmen(feature) {
      this.$set(this.features, this.featureIndex, feature);
    },
  },
};
</script>
  