import Router from "vue-router";

import produktRoutes from "./routes/Produkt";


const baseRoutes = [
	{
		path: "/login",
		name: "login",
		component: () => import("./pages/Login"),
	},
	{
		path: "/",
		name: "Startseite",
		component: () => import("@/pages/Startseiten/Startseite"),
	},
	{
		path: "*",
		name: "ERP Startseite",
		component: () => import("@/pages/Startseiten/Startseite"),
	},
	{
		path: "/error/:errorcode",
		name: "errorpage",
		component: () => import("./pages/Errorpage"),
		props: (route) => {
			return {
				errorcode: route.params.errorcode,
			};
		},
	},
];

const routes = baseRoutes.concat(
	produktRoutes,
);

export default new Router({
	mode: "history",
	base: process.env.VUE_APP_BASE_URL,
	routes: routes,
});
