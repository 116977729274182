<template>
  <div :id="id" class="modal fade" style="z-index: 9997">
    <div class="modal-dialog modal-xl">
      <div class="modal-content" style="border: 1px solid black">
        <div class="modal-header">
          <modal-close-button @confirmed="abbrechen" />
          <h4 class="modal-title">Dokument suchen</h4>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-xl-3">
              <div class="form-group">
                <input
                  ref="editBezeichnung"
                  v-model.trim="filterBezeichnung"
                  class="form-control"
                  placeholder=" "
                  @keyup.enter="resetLoading()"
                />
                <label>Bezeichnung</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 block modal-liste br-t-l-0">
              <div style="height: 300px !important">
                <b-table
                  responsive
                  sticky-header
                  selectable
                  select-mode="single"
                  @row-dblclicked="rowDblClick"
                  @row-selected="onRowSelected"
                  tbody-tr-class="item"
                  :items="dokumente"
                  :fields="fields"
                  :busy="isBusy"
                  :show-empty="loaded"
                >
                  <template #table-busy>
                    <div class="text-center text-primary my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #empty>
                    <div class="text-center text-primary my-2">
                      Keine Datensätze gefunden
                    </div>
                  </template>
                </b-table>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="abbrechen">
            Abbrechen
          </button>
          <button
            type="button"
            class="btn btn-primary"
            @click="uebernehmen"
            :disabled="selected && Object.keys(selected).length === 0"
          >
            Übernehmen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import Api from "@/Api";
import ModalCloseButton from "@/components/global/ModalCloseButton";
import { BSpinner } from "bootstrap-vue";

import { textNotLinked } from "@/defaultTexts.js";

export default {
  name: "DokumentSucheModal",
  components: {
    ModalCloseButton,
    BSpinner,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "dokument-suche-modal",
    },
    index: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  data() {
    return {
      isBusy: false,
      loaded: false,
      anzahlProPage: 99,
      dokumente: [],
      fields: [
        {
          key: "bezeichnung",
          sortable: true,
          label: "Bezeichnung",
        },
        {
          key: "anzeige_de",
          sortable: true,
          label: "Anzeige DE",
        },
        {
          key: "anzeige_en",
          sortable: true,
          label: "Anzeige EN",
        },
        {
          key: "anzeige_fr",
          sortable: true,
          label: "Anzeige FR",
        },
      ],
      selected: {},
      filterBezeichnung: "",
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        count: this.anzahlProPage,
      };

      params.bezeichnung = this.filterBezeichnung;

      return params;
    },
  },
  watch: {},
  mounted() {
    this.$bus.$on("oeffneDokumentSucheModal", (id) => {
      if (id == this.id && !this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      this.shown = true;

      let that = this;

      $("#" + this.id).on("shown.bs.modal", function () {
        if (that.$refs && that.$refs.editName) that.$refs.editName.focus();
      });

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    onRowSelected(item) {
      this.selected = item[0];
    },

    rowDblClick(item) {
      this.selected = item;
      this.uebernehmen();
    },

    resetLoading() {
      this.isBusy = true;

      Api.get("dokument/", {
        params: this.queryParams,
      })
        .then((response) => {
          //response.data.shift();
          this.dokumente = response.data;
        })
        .finally(() => {
          this.isBusy = false;
          this.loaded = true;
        });
    },

    uebernehmen() {
      if (Object.keys(this.selected).length === 0) return;
      this.closeModal();
      this.$emit("confirmed", this.selected);
    },

    abbrechen() {
      this.closeModal();
    },
  },
};
</script>
	
	<style>
</style>
	