<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline class="col-xl-12" headline="Dateien" :col="3">
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4"></div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="mimes"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              show-empty
            >
            </b-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
	  
<script>
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";

export default {
  name: "Dateireiter",
  components: {
    ContainerHeadline,
  },
  mixins: [reiter],
  props: {
    shown: {
      type: Boolean,
      required: false,
    },
    produktid: {
      type: String,
      required: false,
    },
    mimes: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 50,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      selected: [],
      selectedIds: [],
      fields: [
        {
          key: "mime_source",
          sortable: true,
          label: "Dateiname",
        },
        {
          key: "mime_desc",
          sortable: true,
          label: "Beschreibung",
        },
        {
          key: "mime_type",
          sortable: true,
          label: "Dateiformat",
        },
        {
          key: "mime_alt",
          sortable: true,
          label: "Typ",
        },
        {
          key: "mime_purpose",
          sortable: true,
          label: "Zweck",
        },
      ],
    };
  },
  watch: {},
  mounted() {},
  created() {},
  methods: {},
};
</script>
	  