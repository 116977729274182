<template>
  <div class="row">
    <div class="col-xl-12">
      <container-headline class="col-xl-12" headline="Produkte" :col="3">
        <div class="row mt-2 d-flex justify-content-between">
          <div class="ml-4">
            <button class="btn btn-success" @click="oeffneProduktSucheModal">
              <font-awesome-icon icon="fa-regular fa-plus" />
              Produkt zuordnen
            </button>
          </div>
        </div>
      </container-headline>
      <div class="row col-xl-12">
        <div class="col-xl-12 block belegliste br-t-l-0">
          <div>
            <b-table
              ref="selectableTable"
              tbody-tr-class="item"
              :items="produkte"
              :fields="fields"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              :busy="isBusy"
              sort-icon-left
              fixed
              selectable
              select-mode="range"
              show-empty
              @row-dblclicked="oeffneProdukt"
            >
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>

              <template #empty>
                <div v-if="ladeFehler" class="text-center text-danger my-2">
                  <strong>Fehler beim Laden</strong>
                </div>
                <div v-if="!ladeFehler" class="text-center text-primary my-2">
                  <strong>Keine Daten vorhanden.</strong>
                </div>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>

    <produkt-suche-modal @confirmed="prdAdd" id="produkt-suche-modal" />
  </div>
</template>
  
  <script>
import ContainerHeadline from "@/components/ContainerHeadline";
import reiter from "@/mixins/Reiter";
import ProduktSucheModal from "@/components/Modals/ProduktSucheModal.vue";

export default {
  name: "Produktreiter",
  components: {
    ContainerHeadline,
    ProduktSucheModal,
  },
  mixins: [reiter],
  props: {
    shown: {
      type: Boolean,
      required: false,
    },
    produktgruppeid: {
      type: String,
      required: true,
    },
    produkte: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      page: 0,
      anzahlProPage: 50,
      sortBy: "",
      sortDesc: false,
      isBusy: false,
      ladeFehler: false,
      fields: [
        {
          key: "kurzbezeichnungde",
          sortable: true,
          label: "Kurzbezeichnung",
        },
        {
          key: "bezeichnungde",
          sortable: true,
          label: "Bezeichnung",
        },
        {
          key: "internationale_pid",
          sortable: true,
          label: "Internationale Produkt-ID",
        },
        {
          key: "lieferant_pid",
          sortable: true,
          label: "Lieferant Produkt-ID",
        },
        {
          key: "lieferant_pid_bopla",
          sortable: true,
          label: "Bopla Produkt-ID",
        },
        {
          key: "lieferant_pid_rose",
          sortable: true,
          label: "Rose Produkt-ID",
        },
        {
          key: "modelnummer",
          sortable: true,
          label: "Modellnummer",
        },
      ],
      selected: [],
      selectedIds: [],
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  created() {},
  methods: {
    oeffneProdukt(produkt) {
      this.$router.push({
        name: "produkt",
        params: { produktid: produkt.id, anzeige: 0 },
      });
    },
    oeffneProduktSucheModal() {
      this.$bus.$emit("oeffneProduktSucheModal", "produkt-suche-modal");
    },
    prdAdd(produkte) {
      if (produkte) {
        produkte.forEach((produkt) => {
          this.produkte.push(produkt);
        });
      }
    },
  },
};
</script>
  