<template>
  <div :id="id" class="modal fade" style="z-index: 9997">
    <div class="modal-dialog modal-md">
      <div class="modal-content" style="border: 1px solid black">
        <div class="modal-header">
          <modal-close-button @confirmed="abbrechen" />
          <h4 class="modal-title">Feature bearbeiten</h4>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editFeature.featurename.bezeichnung"
                  class="form-control"
                  placeholder="Feature Bezeichnung"
                  :readonly="true"
                />
                <label>Feature Bezeichnung</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editFeature.featurename.beschreibungde"
                  class="form-control"
                  placeholder="Feature Beschreibung DE"
                  :readonly="true"
                />
                <label>Feature Beschreibung DE</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editFeature.featurename.beschreibungen"
                  class="form-control"
                  placeholder="Feature Beschreibung EN"
                  :readonly="true"
                />
                <label>Feature Beschreibung EN</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editFeature.featurename.beschreibungfr"
                  class="form-control"
                  placeholder="Feature Beschreibung FR"
                  :readonly="true"
                />
                <label>Feature Beschreibung EN</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <b-form-checkbox
                  v-model="editFeature.featurename.anzeige"
                  :disabled="true"
                ></b-form-checkbox>
                <label>Anzeige</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editFeature.wert"
                  class="form-control"
                  placeholder="Wert"
                />
                <label>Wert</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editFeature.secondarywert"
                  class="form-control"
                  placeholder="Sekundär-Wert"
                />
                <label>Sekundär-Wert</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editFeature.wertbezeichnungde"
                  class="form-control"
                  placeholder="Wert-Bezeichnung DE"
                />
                <label>Wert-Bezeichnung DE</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editFeature.wertbezeichnungen"
                  class="form-control"
                  placeholder="Wert-Bezeichnung EN"
                />
                <label>Wert-Bezeichnung EN</label>
              </div>
            </div>
            <div class="col-xl-12">
              <div class="form-group">
                <input
                  v-model.trim="editFeature.wertbezeichnungfr"
                  class="form-control"
                  placeholder="Wert-Bezeichnung FR"
                />
                <label>Wert-Bezeichnung FR</label>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-default" @click="abbrechen">
            Abbrechen
          </button>
          <button type="button" class="btn btn-primary" @click="uebernehmen">
            Übernehmen
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
	  
<script>
import ModalCloseButton from "@/components/global/ModalCloseButton";

export default {
  name: "FeatureBearbeitenModal",
  components: {
    ModalCloseButton,
  },
  props: {
    id: {
      type: String,
      required: false,
      default: "feature-bearbeiten-modal",
    },
  },
  data() {
    return {
      editFeature: {
        wertbezeichnungde: "",
        wertbezeichnungen: "",
        wertbezeichnungfr: "",
        secondarywert: "",
        wert: "",
        featurename: {
          //allgemeiner Name zb Masse
          anzeige: null,
          bezeichnung: "",
          beschreibungde: "",
          beschreibungen: "",
          beschreibungfr: "",
        },
      },
    };
  },
  computed: {},
  watch: {},
  mounted() {
    this.$bus.$on("oeffneFeatureBearbeitenModal", (data) => {
      this.editFeature = Object.assign({}, data);
      if (!this.shown) this.openModal();
    });
  },
  methods: {
    openModal() {
      this.shown = true;

      let that = this;

      $("#" + this.id).on("shown.bs.modal", function () {
        if (that.$refs && that.$refs.editName) that.$refs.editName.focus();
      });

      $("#" + this.id).modal({
        backdrop: "static",
        keyboard: false,
      });
    },

    closeModal() {
      this.shown = false;
      $("#" + this.id).modal("hide");
    },

    onRowSelected(item) {
      this.selected = item[0];
    },

    rowDblClick(item) {
      this.selected = item;
      this.uebernehmen();
    },
    uebernehmen() {
      this.closeModal();
      this.$emit("confirmed", this.editFeature);
    },

    abbrechen() {
      this.closeModal();
    },
  },
};
</script>
	  
<style>
</style>
	  