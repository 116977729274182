<template>
  <div class="kopf">
    <div class="container-fluid" :style="[{ 'background-color': '#124862' }]">
      <div class="left-buttons" style="z-index: 5">
        <div class="navbar-button">
          <router-link to="/app/">
            <font-awesome-icon icon="fa-duotone fa-home" />
            <span>Home</span>
          </router-link>
        </div>
        <div class="navbar-button">
          <div class="clickable" @click="$router.go(-1)">
            <font-awesome-icon icon="fa-regular fa-angle-left" size="lg" />
            <span>Zurück</span>
          </div>
        </div>
        <div class="navbar-button">
          <div class="clickable" @click="$router.go(1)">
            <font-awesome-icon icon="fa-regular fa-angle-right" size="lg" />
            <span>Vor</span>
          </div>
        </div>
        <div class="navbar-button">
          <a href="#" onclick="window.location.reload(true);">
            <font-awesome-icon icon="fa-regular fa-sync" />
            <span>Refresh</span>
          </a>
        </div>
      </div>
      <div class="HeaderTitle text-left ml-5" style="padding-left: 150px">
        <span> Product Information Management </span>
      </div>
      <div class="right-buttons">
        <div class="navbar-button">
          <router-link :to="{ name: 'produkt-liste' }">
            <font-awesome-icon icon="fa-duotone fa-box" />
            <span>Produkte</span>
          </router-link>
        </div>

        <div class="navbar-button">
          <router-link :to="{ name: 'produktgruppen-liste' }">
            <font-awesome-icon icon="fa-duotone fa-boxes-stacked" />
            <span>Gruppen</span>
          </router-link>
        </div>

        <div class="usersign" style="padding-left: 10px; top: 11px"></div>

        <div class="navbar-button">
          <div class="clickable" @click="logout()">
            <font-awesome-icon icon="fa-duotone fa-door-open" />
            <span>Logout</span>
          </div>
        </div>

        <div class="usersign" style="padding-left: 25px; top: 11px">
          <div class="dropdown show">
            <a
              class="none-decoration"
              role="button"
              id="dropdownMenuLink"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon
                aria-hidden="true"
                icon="fa-duotone fa-user"
                style="margin-bottom: 0.35rem"
                size="2x"
              />
              <span>{{ name }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import store from "@/store";
import axios from "axios";
import server from "../server";

export default {
  name: "Navbar",
  components: {},
  props: {
    icon: { type: String, default: null },
  },

  store,
  computed: {
    name() {
      return this.$store ? this.$store.getters.name : "";
    },
    logoutUrl() {
      if (process.env.NODE_ENV === "development") {
        return `${server.url}logout/`;
      } else {
        return "/logout/";
      }
    },
  },
  methods: {
    async logout() {
      try {
        await axios.post(this.logoutUrl, {});
        window.location.href = "/";
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
	
	<style lang="scss">
.bm-item-list {
  margin-left: 5%;
}
</style>
	