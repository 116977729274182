<template>
  <div :class="{ 'mt-30-px': !showNavbar }">
    <notifications></notifications>
    <navbar v-if="showNavbar" title="Product Information Management"></navbar>

    <ValidationObserver v-slot="{ invalid }">
      <div class="container-fluid row">
        <div v-if="showNavbar" class="col-xl-12">
          <div class="row">
            <div class="col-xl-12 block br-t-l-0 mb-3">
              <head-menu
                :col="12"
                class="d-xl-flex"
                :headline="navbarTitel"
              ></head-menu>
              <div class="row mt-2 d-flex justify-content-between">
                <div class="ml-4 mr-2">
                  <button
                    v-if="editable"
                    v-shortkey.once="['ctrl', 's']"
                    class="btn btn-success mr-2"
                    :disabled="invalid"
                    @click="speichern"
                    @shortkey="speichern"
                  >
                    Speichern
                  </button>
                  <button class="btn btn-primary mr-2" @click="abbrechen">
                    <font-awesome-icon
                      icon="fa-solid fa-list"
                      class="mr-2"
                    />Zur Liste
                  </button>
                </div>
                <!--
                <div class="mr-4">
                  <button class="btn btn-danger" @click="oeffneLoeschenModal">
                    <font-awesome-icon
                      class="mr-1"
                      icon="fa-duotone fa-trash"
                    />
                    <span>Löschen</span>
                  </button>
                </div>
				-->
              </div>
            </div>
          </div>
        </div>
        <!-- START Linke Seite -->
        <div class="col-xl-6 pr-4" :class="{ 'col-xl-12': !showNavbar }">
          <!-- START Stammdaten -->
          <div class="row">
            <div
              class="block block-kopf clickable"
              @click="setStammdatenSprache('DE')"
              :style="
                stammdatenSprache == 'DE'
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0">Stammdaten - DE</span>
            </div>
            <div
              class="block block-kopf clickable"
              @click="setStammdatenSprache('EN')"
              :style="
                stammdatenSprache == 'EN'
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0">EN</span>
            </div>
            <div
              class="block block-kopf clickable"
              @click="setStammdatenSprache('FR')"
              :style="
                stammdatenSprache == 'FR'
                  ? styleHeadlineSelected
                  : styleHeadline
              "
            >
              <span class="col primary-headline-text p-0">FR</span>
            </div>
          </div>
          <!--
          <div class="row d-flex">
            <container-headline
              :headline="$t('global.masterdata') + ' - DE'"
              :col="6"
            ></container-headline>
            <container-headline
              :headline="$t('global.masterdata') + ' - EN'"
              :col="3"
            ></container-headline>
          </div>-->
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div class="eingabe-panel-kopf p-3">
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produktgruppe.bezeichnung_de"
                        placeholder="Bezeichnung/Titel DE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produktgruppe.bezeichnung_en"
                        placeholder="Bezeichnung/Titel EN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produktgruppe.bezeichnung_fr"
                        placeholder="Bezeichnung/Titel FR"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Bezeichnung/Titel</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produktgruppe.beschreibung_de"
                        placeholder="Beschreibung/Untertitel DE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produktgruppe.beschreibung_en"
                        placeholder="Beschreibung/Untertitel EN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produktgruppe.beschreibung_fr"
                        placeholder="Beschreibung/Untertitel FR"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Beschreibung/Untertitel</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <ckeditor
                        v-if="stammdatenSprache == 'DE'"
                        :editor="editor"
                        v-model="produktgruppe.beschreibung_long_de"
                        :disabled="!editable"
                        :config="editorConfig"
                        :key="'beschreibung_lang' + technischeDatenSprache"
                      ></ckeditor>
                      <ckeditor
                        v-if="stammdatenSprache == 'EN'"
                        :editor="editor"
                        v-model="produktgruppe.beschreibung_long_en"
                        :disabled="!editable"
                        :config="editorConfig"
                        :key="'beschreibung_lang' + technischeDatenSprache"
                      ></ckeditor>
                      <ckeditor
                        v-if="stammdatenSprache == 'FR'"
                        :editor="editor"
                        v-model="produktgruppe.beschreibung_long_fr"
                        :disabled="!editable"
                        :config="editorConfig"
                        :key="'beschreibung_lang' + technischeDatenSprache"
                      ></ckeditor>
                      <!--
                      <textarea
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produktgruppe.beschreibung_long_de"
                        placeholder="Beschreibung lang DE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <textarea
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produktgruppe.beschreibung_long_en"
                        placeholder="Beschreibung lang EN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <textarea
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produktgruppe.beschreibung_long_fr"
                        placeholder="Beschreibung lang FR"
                        class="form-control"
                        :readonly="!editable"
                      />-->
                      <label>Beschreibung lang</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-if="stammdatenSprache == 'DE'"
                        v-model="produktgruppe.pfad_de"
                        placeholder="Pfad DE"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'EN'"
                        v-model="produktgruppe.pfad_en"
                        placeholder="Pfad EN"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <input
                        v-if="stammdatenSprache == 'FR'"
                        v-model="produktgruppe.pfad_fr"
                        placeholder="Pfad FR"
                        class="form-control"
                        :readonly="!editable"
                      />
                      <label>Pfad</label>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-4 position-relative">
                    <img
                      v-if="
                        produktgruppe.bild &&
                        Object.keys(produktgruppe.bild).length != 0 &&
                        produktgruppe.bild.bildURL
                      "
                      class="w-100 h-100"
                      :src="produktgruppe.bild.bildURL"
                      style="
                        object-fit: contain;
                        object-position: center center;
                        max-height: 200px;
                      "
                    />
                    <div
                      class="w-100 h-100 bg-medium-blue"
                      v-if="
                        !produktgruppe.bild ||
                        Object.keys(produktgruppe.bild).length === 0 ||
                        !produktgruppe.bild.bildURL
                      "
                    ></div>
                    <div class="position-absolute top-0 right-53">
                      <div class="form-group d-flex">
                        <div
                          class="btn btn-sm btn-success ml-1 p-0"
                          @click="oeffneBildSucheModal()"
                        >
                          <font-awesome-icon
                            class="px-2"
                            icon="fa-regular fa-plus"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="position-absolute top-0 right-20">
                      <div class="form-group d-flex">
                        <div
                          class="btn btn-sm btn-danger ml-1 p-0"
                          @click="loescheBild()"
                        >
                          <font-awesome-icon
                            class="px-2"
                            icon="fa-duotone fa-trash"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-8" v-if="produktgruppe.bild">
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          v-if="stammdatenSprache == 'DE'"
                          v-model="produktgruppe.bild.dateinamede"
                          class="form-control"
                          placeholder="Dateiname DE"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'EN'"
                          v-model="produktgruppe.bild.dateinameen"
                          class="form-control"
                          placeholder="Dateiname EN"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'FR'"
                          v-model="produktgruppe.bild.dateinamefr"
                          class="form-control"
                          placeholder="Dateiname FR"
                          :readonly="!editable"
                        />
                        <label>Bezeichnung</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          v-if="stammdatenSprache == 'DE'"
                          v-model="produktgruppe.bild.titletagde"
                          placeholder="Titel Tag DE"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'EN'"
                          v-model="produktgruppe.bild.titletagen"
                          placeholder="Titel Tag EN"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'FR'"
                          v-model="produktgruppe.bild.titletagfr"
                          placeholder="Titel Tag FR"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Titel Tag</label>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="form-group">
                        <input
                          v-if="stammdatenSprache == 'DE'"
                          v-model="produktgruppe.bild.alttagde"
                          placeholder="Alt Tag DE"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'EN'"
                          v-model="produktgruppe.bild.alttagen"
                          placeholder="Alt Tag EN"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'FR'"
                          v-model="produktgruppe.bild.alttagfr"
                          placeholder="Alt Tag FR"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Alt Tag</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-2 mt-5">
                  <div class="col-xl-6">
                    <div class="btn btn-primary" @click="addDokument()">
                      <font-awesome-icon
                        class="px-2 mr-1"
                        icon="fa-regular fa-plus"
                      /><span>Dokument hinzufügen</span>
                    </div>
                  </div>
                </div>
                <div
                  class="row py-2 border-bottom"
                  v-for="(dokument, index) in produktgruppe.dokumente"
                  :key="'dokument-' + index"
                >
                  <div class="col-12 d-flex">
                    <div class="col-5 p-0">
                      <div class="form-group">
                        <input
                          v-if="stammdatenSprache == 'DE'"
                          v-model="dokument.anzeige_de"
                          placeholder="Anzeige DE"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'EN'"
                          v-model="dokument.anzeige_en"
                          placeholder="Anzeige EN"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="stammdatenSprache == 'FR'"
                          v-model="dokument.anzeige_fr"
                          placeholder="Anzeige FR"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Anzeige</label>
                      </div>
                    </div>
                    <div class="col-5">
                      <div class="form-group">
                        <input
                          v-model="dokument.bezeichnung"
                          placeholder="Bezeichnung"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Bezeichnung</label>
                      </div>
                    </div>
                    <div class="col-1">
                      <div class="form-group d-flex">
                        <div
                          class="btn btn-sm btn-success ml-1"
                          @click="oeffneDokumentSucheModal(index)"
                        >
                          <font-awesome-icon
                            class="px-2"
                            icon="fa-regular fa-plus"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-1">
                      <div class="form-group d-flex">
                        <div
                          class="btn btn-sm btn-danger ml-1"
                          @click="loescheDokument(index)"
                        >
                          <font-awesome-icon
                            class="px-2"
                            icon="fa-duotone fa-trash"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- ENDE Stammdaten -->
        </div>
        <!-- ENDE linke Seite -->
        <!-- START Rechte Seite -->
        <!-- START Technische Daten -->
        <div class="col-xl-6 pl-4" :class="{ 'col-xl-12': !showNavbar }">
          <div class="row">
            <div
              class="block block-kopf clickable"
              @click="setTechnischeDatenSprache('DE')"
              :style="
                technischeDatenSprache == 'DE'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0"
                >Technische Daten - DE</span
              >
            </div>
            <div
              class="block block-kopf clickable"
              @click="setTechnischeDatenSprache('EN')"
              :style="
                technischeDatenSprache == 'EN'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0">EN</span>
            </div>
            <div
              class="block block-kopf clickable"
              @click="setTechnischeDatenSprache('FR')"
              :style="
                technischeDatenSprache == 'FR'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0">FR</span>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div
                class="eingabe-panel-kopf p-3 technischeDatenPanel"
                ref="technischeDatenPanel"
                :style="
                  produktgruppe.technischeDaten.length > 1
                    ? panelStyle
                    : 'height: 420px;'
                "
              >
                <div class="row mb-2">
                  <div class="col-xl-6">
                    <div class="btn btn-primary" @click="addTechnischeDaten()">
                      <font-awesome-icon
                        class="px-2 mr-1"
                        icon="fa-regular fa-plus"
                      /><span>Technische Daten hinzufügen</span>
                    </div>
                  </div>
                </div>
                <div
                  class="py-2 border-bottom"
                  v-for="(tDaten, index) in produktgruppe.technischeDaten"
                  :key="'tDaten' + index"
                >
                  <div v-if="produktgruppe.technischeDaten">
                    <div class="row">
                      <div class="col-xl-8">
                        <div class="form-group d-flex">
                          <input
                            v-if="technischeDatenSprache == 'DE'"
                            v-model="tDaten.titel_de"
                            placeholder="Titel DE"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <input
                            v-if="technischeDatenSprache == 'EN'"
                            v-model="tDaten.titel_en"
                            placeholder="Titel EN"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <input
                            v-if="technischeDatenSprache == 'FR'"
                            v-model="tDaten.titel_fr"
                            placeholder="Titel FR"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>Angabe</label>
                        </div>
                      </div>
                      <div class="col-xl-3">
                        <div class="form-group d-flex">
                          <input
                            v-model.number="tDaten.sortierung"
                            placeholder="Sortierung"
                            class="form-control"
                            :readonly="!editable"
                          />
                          <label>Sortierung</label>
                        </div>
                      </div>
                      <div class="">
                        <div class="form-group d-flex">
                          <div
                            class="btn btn-sm btn-danger ml-1"
                            @click="loescheTechnischeDaten(index)"
                          >
                            <font-awesome-icon
                              class="px-2"
                              icon="fa-duotone fa-trash"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xl-12">
                        <div class="form-group">
                          <ckeditor
                            v-if="technischeDatenSprache == 'DE'"
                            :editor="editor"
                            v-model="tDaten.beschreibung_de"
                            :disabled="!editable"
                            :config="editorConfig"
                            :key="'beschreibung' + technischeDatenSprache"
                          ></ckeditor>
                          <ckeditor
                            v-if="technischeDatenSprache == 'EN'"
                            :editor="editor"
                            v-model="tDaten.beschreibung_en"
                            :disabled="!editable"
                            :config="editorConfig"
                            :key="'beschreibung' + technischeDatenSprache"
                          ></ckeditor>
                          <ckeditor
                            v-if="technischeDatenSprache == 'FR'"
                            :editor="editor"
                            v-model="tDaten.beschreibung_fr"
                            :disabled="!editable"
                            :config="editorConfig"
                            :key="'beschreibung' + technischeDatenSprache"
                          ></ckeditor>
                          <label>Wert</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--ENDE Technische Daten -->
          <!-- START Bulletpoints -->
          <div class="row">
            <div
              class="block block-kopf clickable"
              @click="setBulletpointsSprache('DE')"
              :style="
                bulletpointsSprache == 'DE'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0"
                >Bulletpoints - DE</span
              >
            </div>
            <div
              class="block block-kopf clickable"
              @click="setBulletpointsSprache('EN')"
              :style="
                bulletpointsSprache == 'EN'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0">EN</span>
            </div>
            <div
              class="block block-kopf clickable"
              @click="setBulletpointsSprache('FR')"
              :style="
                bulletpointsSprache == 'FR'
                  ? { filter: 'none' }
                  : { filter: 'brightness(0.9)' }
              "
            >
              <span class="col primary-headline-text p-0">FR</span>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-xl-12 block br-t-l-0">
              <div
                class="eingabe-panel-kopf p-3 bulletpointsPanel"
                ref="bulletpointsPanel"
                :style="
                  produktgruppe.bulletpoints.length > 1
                    ? panelStyle
                    : 'height: 420px;'
                "
              >
                <div class="row mb-2">
                  <div class="col-xl-6">
                    <div class="btn btn-primary" @click="addBulletpoint()">
                      <font-awesome-icon
                        class="px-2 mr-1"
                        icon="fa-regular fa-plus"
                      /><span>Bulletpoint hinzufügen</span>
                    </div>
                  </div>
                </div>
                <div
                  class="py-2 border-bottom"
                  v-for="(bulletpoint, index) in produktgruppe.bulletpoints"
                  :key="'bulletpoint' + index"
                >
                  <div class="row">
                    <div class="col-xl-8">
                      <div class="form-group d-flex">
                        <input
                          v-if="bulletpointsSprache == 'DE'"
                          v-model="bulletpoint.titel_de"
                          placeholder="Titel DE"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="bulletpointsSprache == 'EN'"
                          v-model="bulletpoint.titel_en"
                          placeholder="Titel EN"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <input
                          v-if="bulletpointsSprache == 'FR'"
                          v-model="bulletpoint.titel_fr"
                          placeholder="Titel FR"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Angabe</label>
                      </div>
                    </div>
                    <div class="col-xl-3">
                      <div class="form-group d-flex">
                        <input
                          v-model.number="bulletpoint.sortierung"
                          placeholder="Sortierung"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Sortierung</label>
                      </div>
                    </div>
                    <div class="">
                      <div class="form-group d-flex">
                        <div
                          class="btn btn-sm btn-danger ml-1"
                          @click="loescheBulletpoint(index)"
                        >
                          <font-awesome-icon
                            class="px-2"
                            icon="fa-duotone fa-trash"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xl-12">
                      <div class="form-group">
                        <textarea
                          v-if="bulletpointsSprache == 'DE'"
                          v-model="bulletpoint.beschreibung_de"
                          placeholder="Wert DE"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <textarea
                          v-if="bulletpointsSprache == 'EN'"
                          v-model="bulletpoint.beschreibung_en"
                          placeholder="Wert EN"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <textarea
                          v-if="bulletpointsSprache == 'FR'"
                          v-model="bulletpoint.beschreibung_fr"
                          placeholder="Wert FR"
                          class="form-control"
                          :readonly="!editable"
                        />
                        <label>Wert</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--ENDE Bulletpoints -->
        </div>
        <!-- ENDE Rechte Seite -->

        <!-- REITER START -->

        <!-- HEADER REITER -->

        <div class="col-12">
          <div class="row">
            <div class="col-12 p-0">
              <ul class="nav nav-tabs mt-3 fs-28">
                <li class="nav item">
                  <router-link
                    class="primary-headline-text nav-link"
                    :class="{ active: anzeige == 0 }"
                    :to="{
                      name: 'produktgruppe',
                      params: { produktgruppeid: produktgruppeid, anzeige: 0 },
                    }"
                    >Produkte</router-link
                  >
                </li>
              </ul>
            </div>

            <!-- ENDE HEADER REITER -->

            <!-- REITER CONTENT -->
            <div class="col-12 p-0">
              <div class="tab-container p-3">
                <div v-show="anzeige === 0">
                  <produkt-reiter
                    :produkte="produktgruppe.produkte"
                    :produktgruppeid="produktgruppeid"
                  />
                </div>
              </div>
            </div>
            <!-- REITER CONTENT ENDE -->
          </div>
        </div>
        <!-- REITER ENDE -->
      </div>
    </ValidationObserver>

    <loading-overlay v-show="loading"></loading-overlay>

    <loeschen-modal
      id="produktgruppe-loeschen-modal"
      @confirmed="produktgruppeLoeschen"
    />

    <bild-suche-modal id="bild-suche-modal" @confirmed="setzeBild" />

    <dokument-suche-modal
      id="dokument-suche-modal"
      @confirmed="setzeDokument"
    />

    <change-watcher-alert />
  </div>
</template>
    
    <script>
import Api from "@/Api";
import page from "@/mixins/Page";
import ChangeWatcher from "@/mixins/ChangeWatcher";

import HeadMenu from "@/components/HeadMenu";
import Navbar from "@/components/Navbar";

import LoeschenModal from "@/components/Modals/LoeschenModal.vue";
import BildSucheModal from "@/components/Modals/BildSucheModal.vue";
import DokumentSucheModal from "@/components/Modals/DokumentSucheModal.vue";

import { apiErrorToAlert } from "@/utils/Errorhandler";

import store from "@/store";

import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import ProduktReiter from "@/components/Reiter/Produktreiter.vue";

export default {
  name: "Produktgruppe",
  components: {
    HeadMenu,
    Navbar,
    LoeschenModal,
    ProduktReiter,
    BildSucheModal,
    DokumentSucheModal,
  },
  mixins: [page, ChangeWatcher],
  store,
  props: {
    produktgruppeid: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      stammdatenSprache: "DE",
      technischeDatenSprache: "DE",
      bulletpointsSprache: "DE",
      styleHeadlineSelected: { filter: "none" },
      styleHeadline: { filter: "brightness(0.9)" },
      panelStyle: {
        height: "420px",
        "overflow-y": "scroll",
      },
      produktgruppe: {
        technischeDaten: [],
        bulletpoints: [],
      },
      /*
      produktgruppe: {
        id: "123",
        bezeichnung_de: "Produktgruppe 1",
        bezeichnung_en: "Product group 1",
        bezeichnung_fr: "Groupe de product 1",
        beschreibung_de: "Produktgruppenbeschreibung 1",
        beschreibung_en: "Product group descripion 1",
        beschreibung_fr: "Description du Groupe de product 1",
        beschreibung_long_de: "Produktgruppenbeschreibung lang 1",
        beschreibung_long_en: "Product group descripion long 1",
        beschreibung_long_fr: "Description du Groupe de product longue 1",
        pfad_de: "url de",
        pfad_en: "url en",
        pfad_fr: "url fr",
        bulletpoints: [
          {
            titel_de: "Titel DE",
            titel_en: "Titel EN",
            titel_fr: "Titel FR",
            sortierung: 10,
            beschreibung_de: "Beschreibung DE",
            beschreibung_en: "Beschreibung EN",
            beschreibung_fr: "Beschreibung FR",
          },
        ],
        technischeDaten: [
          {
            titel_de: "Titel DE",
            titel_en: "Titel EN",
            titel_fr: "Titel FR",
            sortierung: 10,
            beschreibung_de: "Beschreibung DE",
            beschreibung_en: "Beschreibung EN",
            beschreibung_fr: "Beschreibung FR",
          },
        ],
        bild: {
          bildURL:
            "http://phoenix-mecano.batix.ch/pic/184C3D4CF49/Aluminiumgehaeuse.png",
          dateinamede: "Bezeichnung DE",
          titletagde: "Titel Tag DE",
          alttagde: "Alt Tag DE",
          dateinameen: "Bezeichnung EN",
          titletagen: "Titel Tag EN",
          alttagen: "Alt Tag EN",
          dateinamefr: "Bezeichnung FR",
          titletagfr: "Titel Tag FR",
          alttagfr: "Alt Tag FR",
        },
        dokumente: [
          {
            id: "ID",
            anzeige_de: "anzeige DE",
            anzeige_en: "anzeige EN",
            anzeige_fr: "anzeige FR",
            bezeichnung: "bezeichnung",
          },
        ],
        produkte: [
          {
            id: null,
            bezeichnungde: null,
            kurzbezeichnungde: null,
            internationale_pid: null,
            modelnummer: null,
          },
        ],
        produktkategorie: {},
        produktgruppeParent: {},
        produktgruppeChilds: [],
      },*/
      editable: true,
      dokumentIndex: 0,
      editor: ClassicEditor,
      editorConfig: {
        toolbar: {
          items: [
            "heading",
            "|",
            "bold",
            "italic",
            "link",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "insertTable",
            "blockQuote",
            "|",
            "undo",
            "redo",
          ],
        },
        table: {
          contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
    };
  },
  computed: {
    navbarTitel: function () {
      if (this.id || this.produktgruppeid) {
        return "Produktgruppe";
      } else {
        return "Neue Produktgruppe";
      }
    },
  },
  watch: {
    produktgruppeid: {
      handler: function (val) {
        if (val) {
          this.ladeProduktgruppe(val);
        }
      },
      immediate: true,
    },
  },

  created() {},

  mounted() {},

  methods: {
    ladeProduktgruppe(id) {
      this.loading = true;

      Api.get("produktgruppe/", {
        params: { id: id },
      })
        .then((response) => {
          this.initializeProduktgruppe(response.data);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    initializeProduktgruppe(data) {
      this.produktgruppe = data;

      if (!this.produktgruppe.bild) {
        this.produktgruppe.bild = {
          bildURL: null,
          dateinamede: null,
          titletagde: null,
          alttagde: null,
          dateinameen: null,
          titletagen: null,
          alttagen: null,
          dateinamefr: null,
          titletagfr: null,
          alttagfr: null,
        };
      }
    },
    abbrechen() {
      this.$router.push({ name: "produktgruppen-liste" });
    },

    speichern() {
      var json = Object.assign({}, this.produktgruppe);

      this.loading = true;

      Api.put("produktgruppe/", json, {
        params: { id: this.produktgruppe.id },
      })
        .then((response) => {
          this.initializeProduktgruppe(response.data);
          this.$notify({
            type: "success",
            title: "Aktion erfolgreich!",
            text: "Produktgruppe gespeichert.",
          });
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        })
        .finally(() => {
          this.loading = false;
        });
    },

    oeffneLoeschenModal() {
      this.$bus.$emit("openLoeschenModal", "produktgruppe-loeschen-modal");
    },

    produktgruppeLoeschen() {},
    setStammdatenSprache(sprache) {
      this.stammdatenSprache = sprache;
    },
    setTechnischeDatenSprache(sprache) {
      this.technischeDatenSprache = sprache;
    },
    setBulletpointsSprache(sprache) {
      this.bulletpointsSprache = sprache;
    },
    addTechnischeDaten() {
      let sortierung = 0;

      if (this.produktgruppe.technischeDaten.length != 0) {
        sortierung =
          this.produktgruppe.technischeDaten[
            this.produktgruppe.technischeDaten.length - 1
          ].sortierung;
      }

      this.produktgruppe.technischeDaten.push({
        titel_de: "",
        titel_en: "",
        titel_fr: "",
        sortierung: parseInt(sortierung) + 10,
        beschreibung_de: "",
        beschreibung_en: "",
        beschreibung_fr: "",
      });
    },
    addBulletpoint() {
      let sortierung = 0;

      if (this.produktgruppe.bulletpoints.length != 0) {
        sortierung =
          this.produktgruppe.bulletpoints[
            this.produktgruppe.bulletpoints.length - 1
          ].sortierung;
      }

      this.produktgruppe.bulletpoints.push({
        titel_de: "",
        titel_en: "",
        titel_fr: "",
        sortierung: parseInt(sortierung) + 10,
        beschreibung_de: "",
        beschreibung_en: "",
        beschreibung_fr: "",
      });
    },
    loescheTechnischeDaten(index) {
      this.produktgruppe.technischeDaten.splice(index, 1);
    },
    loescheBulletpoint(index) {
      this.produktgruppe.bulletpoints.splice(index, 1);
    },
    loescheBild() {
      this.$set(this.produktgruppe, "bild", {});
    },
    oeffneBildSucheModal() {
      this.$bus.$emit("oeffneBildSucheModal", "bild-suche-modal");
    },
    setzeBild(bild) {
      this.$set(this.produktgruppe, "bild", bild);
    },
    addDokument() {
      this.produktgruppe.dokumente.push({
        anzeige_de: "",
        anzeige_en: "",
        anzeige_fr: "",
        bezeichnung: "",
      });
    },
    loescheDokument(index) {
      this.produktgruppe.dokumente.splice(index, 1);
    },
    oeffneDokumentSucheModal(index) {
      this.dokumentIndex = index;
      this.$bus.$emit("oeffneDokumentSucheModal", "dokument-suche-modal");
    },
    setzeDokument(dokument) {
      this.$set(this.produktgruppe.dokumente, this.dokumentIndex, dokument);
    },
  },
};
</script>
    
<style>
.technischeDatenPanel,
.bulletpointsPanel {
  scrollbar-width: thin;
  scrollbar-color: #deedf6 #f5fbff;
}
.technischeDatenPanel::-webkit-scrollbar,
.bulletpointsPanel::-webkit-scrollbar {
  width: 12px;
}

*.technischeDatenPanel::-webkit-scrollbar-track,
.bulletpointsPanel::-webkit-scrollbar-track {
  background: #f5fbff;
  border-radius: 0 0 6px 0;
}

*.technischeDatenPanel::-webkit-scrollbar-thumb,
.bulletpointsPanel::-webkit-scrollbar-thumb {
  background-color: #deedf6;
  border-radius: 20px;
  border: 3px solid #f5fbff;
}
</style>
    