<template>
  <messagebox-enhanced
    headerText="Datensatz speichern"
    id="change-watcher-alert-msgbox"
    :ok="true"
    :cancel="true"
    okText="Verwerfen"
    cancelText="Abbrechen"
    @ok="performIgnore"
  />
</template>

<script>
import MessageboxEnhanced from "@/components/Modals/MessageboxEnhanced.vue";

export default {
  name: "ChangeWatcherAlert",
  components: { MessageboxEnhanced },

  props: {},
  data() {
    return {
      nextFunc: null,
    };
  },
  watch: {},
  mounted: function () {
    this.$bus.$on("saved-needed", (next) => {
      this.nextFunc = next;
      this.$bus.$emit("openMessageBoxEnhanced", {
        id: "change-watcher-alert-msgbox",
        text: "Sie haben ungespeicherte Änderungen. Wollen Sie diese verwerfen?",
      });
    });
  },
  methods: {
    performIgnore() {
      this.nextFunc();
    },
  },
};
</script>